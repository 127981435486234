import * as Sentry from '@sentry/browser'
import $ from 'jquery'

window.$ = window.jQuery = $
window.Sentry = Sentry
const sys = window.sys

window.show_overlay = true
// Safari is bad at showing the overlay and often shows it on back & forward so don't show the overlay at all
const is_safari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1

window.apply_overlay = function () {
  if (window.show_overlay && !is_safari) {
    $('#full-overlay').show()
  }
}

function ls_supported () {
  try {
    window.localStorage.setItem('_test_local_storage', 1)
    if (window.localStorage['_test_local_storage'] !== '1') {
      return false
    }
    window.localStorage.removeItem('_test_local_storage')
  } catch (e) {
    return false
  }
  return true
}

window.localStorageOn = ls_supported()

if (sys.DEBUG || (window.localStorageOn && window.sessionStorage['console_log'])) {
  window.log = (...args) => console.log('tclog:', ...args)
} else {
  window.log_messages = []
  window.log = (...args) => {
    const msg = Array.from(args).join()
    window.log_messages.push(msg)
    Sentry.addBreadcrumb({ message: msg, category: 'console' })
  }
}

window.onbeforeunload = () => {
  setTimeout(window.apply_overlay, 20)
}

const ignored_culprits = ['kaspersky', 'intercomcdn', 'nuanria']
const ignored_everywhere = [
  '__gCrWeb.',
  'siteroot is not defined',
  'QuotaExceededError',
  'ignored_error',
  'isLoading',
  'js/custom', // Ignores Agency custom JS #12385
  'idpiframe_initialization_failed' // Ignore errors created by Users Blocking Third party site data and Cookies #12411
]

const sentry_config = {
  release: sys.COMMIT_SHA,
  autoBreadcrumbs: {
    xhr: true,
    console: !sys.DEBUG,
    dom: true,
    location: true
  },
  beforeSend: (event) => {
    if (window.old_browser) {
      console.error('old browser, not reporting:', event)
      return false
    }
    const culprit = event.culprit || ''

    for (const ignored of ignored_culprits) {
      if (culprit.includes(ignored)) {
        return null
      }
    }

    const data_str = JSON.stringify(event)
    for (const ignored of ignored_everywhere) {
      if (data_str.includes(ignored)) {
        return null
      }
    }

    if (sys.TESTING) {
      console.error('BJSON:' + window.btoa((JSON.stringify(event, 2))))
      return null
    } else if (sys.DEBUG) {
      console.error('Not reporting:', event.message, event)
      return null
    } else {
      console.error('Reporting:', event)
      return event
    }
  }
}

if (sys.SENTRY_FRONTEND_REPLAY) {
  sentry_config.replaysOnErrorSampleRate = 0.1 // Replay 10% of errors, including 1 minute before and till end of session.
  sentry_config.integrations = [new Sentry.Replay()]
}

Sentry.init({
  dsn: sys.SENTRY_FRONTEND_DSN,
  ...sentry_config,
  denyUrls: [/chrome-extension:\/\//],
})

const $head_data = $('#head-data')
if ($head_data.length) {
  const head_data = JSON.parse($head_data.text())
  Sentry.setUser(head_data.raven_data)
  window.responsive = head_data.responsive
}

window.onunhandledrejection = (event) => {
  window.log('Uncaught Promise:', event.reason)
}
